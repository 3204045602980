import React, { Component } from 'react';
import Fade from 'react-reveal';

let id = 0;
class Portfolio extends Component {
  render() {
    if (!this.props.data) return null;

    const projects = this.props.data.projects.map(function (projects) {
      let projectImage = 'images/portfolio/' + projects.image;

      return (
        <div
          key={id++}
          className='portfolio-item'
          onClick={() => {
            window.open(projects.url);
          }}
        >
          <div className='item-wrap'>
            <img alt={projects.title} src={projectImage} />
            <div className='divWrapper'>
              <h1 style={{ textAlign: 'center' }}>{projects.title}</h1>
              <div style={{ height: '100px', textAlign: 'center' }}>
                <pre>{projects.desc}</pre>
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <section id='portfolio'>
        <h1>Check Out Some of My Works.</h1>
        <Fade left duration={1000} distance='40px'>
          <div className=' project-row'>
            <div id='portfolio-wrapper'>{projects}</div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default Portfolio;
