import React, { Component } from 'react';
import { Fade, Slide } from 'react-reveal';
import emailjs, { init } from 'emailjs-com';

class Contact extends Component {
    constructor() {
        super();
        this.state = {
            res: 'none',
        };
    }
    render() {
        const { REACT_APP_SERVICE_ID } = process.env;
        init('user_iMJ8363ENiY5pZJ77qYui');
        if (!this.props.data) return null;
        const onSubmit = async (e) => {
            e.preventDefault();
            this.setState((state) => ({ res: 'loading' }));
            const { status } = await emailjs.sendForm(
                REACT_APP_SERVICE_ID,
                'template_a4dhi9r',
                e.target
            );

            if (status === 200) {
                this.setState((state) => ({ res: 'success' }));
            } else {
                this.setState((state) => ({ res: 'error' }));
            }
            return status;
        };

        const name = this.props.data.name;
        const street = this.props.data.address.street;
        const city = this.props.data.address.city;
        const state = this.props.data.address.state;
        const zip = this.props.data.address.zip;
        const phone = this.props.data.phone;
        const message = this.props.data.contactmessage;

        return (
            <section id='contact'>
                <Fade bottom duration={1000}>
                    <div className='row section-head'>
                        <div className='two columns header-col'>
                            <h1>
                                <span>Get In Touch.</span>
                            </h1>
                        </div>

                        <div className='ten columns'>
                            <p className='lead'>{message}</p>
                        </div>
                    </div>
                </Fade>

                <div className='row'>
                    <Slide left duration={1000}>
                        <div className='eight columns'>
                            <form
                                id='contactForm'
                                name='contactForm'
                                onSubmit={onSubmit}
                            >
                                <div>
                                    <label htmlFor='contactName'>
                                        Name <span className='required'>*</span>
                                    </label>
                                    <input
                                        type='text'
                                        defaultValue=''
                                        size='35'
                                        id='contactName'
                                        name='contactName'
                                        onChange={this.handleChange}
                                    />
                                </div>

                                <div>
                                    <label htmlFor='contactEmail'>
                                        Email{' '}
                                        <span className='required'>*</span>
                                    </label>
                                    <input
                                        type='text'
                                        defaultValue=''
                                        size='35'
                                        id='contactEmail'
                                        name='contactEmail'
                                        onChange={this.handleChange}
                                    />
                                </div>

                                <div>
                                    <label htmlFor='contactSubject'>
                                        Subject
                                    </label>
                                    <input
                                        type='text'
                                        defaultValue=''
                                        size='35'
                                        id='contactSubject'
                                        name='contactSubject'
                                        onChange={this.handleChange}
                                    />
                                </div>

                                <div>
                                    <label htmlFor='contactMessage'>
                                        Message{' '}
                                        <span className='required'>*</span>
                                    </label>
                                    <textarea
                                        cols='50'
                                        rows='15'
                                        id='contactMessage'
                                        name='contactMessage'
                                    ></textarea>
                                </div>

                                <div>
                                    <button type='submit' className='submit'>
                                        Submit
                                    </button>
                                    <span
                                        style={{
                                            display:
                                                this.state.res === 'loading'
                                                    ? 'block'
                                                    : 'none',
                                        }}
                                        id='image-loader'
                                    >
                                        <img alt='' src='images/loader.gif' />
                                    </span>
                                </div>
                            </form>

                            <div
                                style={{
                                    display:
                                        this.state.res === 'error'
                                            ? 'block'
                                            : 'none',
                                }}
                                id='message-warning'
                            >
                                {' '}
                                Error Sending Email
                            </div>
                            <div
                                style={{
                                    display:
                                        this.state.res === 'success'
                                            ? 'block'
                                            : 'none',
                                }}
                                id='message-success'
                            >
                                <i className='fa fa-check'></i>Your message was
                                sent, thank you!
                                <br />
                            </div>
                        </div>
                    </Slide>

                    <Slide right duration={1000}>
                        <aside className='four columns footer-widgets'>
                            <div className='widget widget_contact'>
                                <h4>Address and Phone</h4>
                                <p className='address'>
                                    {name}
                                    <br />
                                    {street} <br />
                                    {city}, {state} {zip}
                                    <br />
                                    <span>{phone}</span>
                                </p>
                            </div>
                        </aside>
                    </Slide>
                </div>
            </section>
        );
    }
}

export default Contact;
